import React from 'react';
import './App.scss';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div>
                    My Chastity App is under construction. Please come back later !
                </div>
            </header>
            <div className="App-body">
                Few tools are under construction to offer you better chastity experiences, what ever you are, a Keyholder,
                Mistress, Master or a wearer, sub, slave, ...
            </div>
        </div>
    );
}

export default App;
